import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Grid, Typography } from 'rc'
import { ChevronRight, Cross } from 'rc/Icons'
import styled from 'styled-components'
import { WiggotIcon } from 'components/Icons'
import { BellIcon } from 'components/monopolioUI'
import { Image } from 'components'

const MenuButton = ({
  onClick,
  isMenuOpened,
  menuHeaderCustomized: MenuHeaderCustomized
}) => {
  const history = useHistory()
  const { photo, fullname, roles, isSelectDevelop } = useSelector(
    ({
      profileReducer: { photo, fullname },
      authReducer: { roles, isSelectDevelop }
    }) => ({ photo, fullname, roles, isSelectDevelop })
  )

  const ROLDEVELOPER =
    roles.DEVELOPER || roles.ROLE_ADMIN_DEVELOPER
      ? isSelectDevelop
        ? true
        : false
      : false

  const GoToProfile = () => {
    onClick()
    if (roles.DEVELOPER_USER || ROLDEVELOPER) {
      return history.push('/developmentAccount')
    }

    if (Object.keys(roles).length > 0 && !roles.ASSOCIATION) {
      return window.location.replace(
        `${process.env.REACT_APP_NEW_APLICATION_URL}/account/profile`
      )
    }

    return history.push('/association/agencies')
  }

  return (
    <Grid
      item
      flat
      xsShrink
      container
      css={`
        background: ${({ theme }) => theme.palette.secondary.main};
        width: 100%;
      `}
    >
      <Grid
        container
        alignItems="center"
        justify="space-between"
        css={`
          height: ${({ theme }) => theme.remCalc('76')};
          width: 100%;
          z-index: 3;
          cursor: pointer;
          padding: 8px 30px 8px 20px;
        `}
        onClick={onClick}
        data-toggle="menu-button"
        id="navigation-side-bar__menu-button"
      >
        <Grid item xsAuto flat css="display: none;">
          <Typography
            variant="subtitle1"
            color="white"
            css={`
              padding-right: 15px;
              padding-left: 5px;
              user-select: none;
            `}
          >
            Menú
          </Typography>
        </Grid>
        <MenuButtonContainerStyled container center inline>
          <ChevronRight
            $isMenuOpened={isMenuOpened}
            css={`
              margin-right: ${({ theme, $isMenuOpened }) =>
                $isMenuOpened ? 0 : theme.remCalc('-2')};
              height: ${({ theme }) => theme.remCalc('20')};
              transform: ${({ $isMenuOpened }) =>
                $isMenuOpened ? 'rotate(180deg)' : 'rotate(0deg)'};
              transition-delay: 0;
              transition-duration: 200ms;
              transition-property: transform;
              transition-timing-function: ease-out;
            `}
          />
        </MenuButtonContainerStyled>
        <WiggotIconStyled />
        <CrossIconStyled />
      </Grid>
      <Separator />
      <MobileMenuHeader>
        <div
          hasBg={!photo}
          css={`
            display: flex;
            overflow: hidden;
            justify-content: center;
            align-items: center;
            ${({ theme }) => theme.box(48, 48, true)}
            ${({ hasBg }) =>
              hasBg &&
              'box-shadow: 0 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px -2px rgba(0,0,0,.1);'}
          `}
          onClick={GoToProfile}
        >
          {photo ? (
            <Image id={photo} />
          ) : fullname ? (
            <Typography component="span" variant="h4" color="inherit">
              {fullname
                .split(' ')
                .map((item, index) => {
                  if (index >= 2) return ''
                  return item.charAt(0)
                })
                .join('')}{' '}
              aaaa
            </Typography>
          ) : (
            ''
          )}
        </div>
        <div
          css={`
            display: flex;
            flex-direction: column;
            flex: 1;
          `}
        >
          <CustomProfileNameText>{fullname || ''}</CustomProfileNameText>
          <CustomActionText onClick={GoToProfile}>
            Editar mi perfil
          </CustomActionText>
        </div>
        <BellIcon css="width: 24px; height: 24px; margin-left: 20px;" />
      </MobileMenuHeader>
    </Grid>
  )
}

MenuButton.propTypes = {
  isMenuOpened: PropTypes.bool,
  menuHeaderCustomized: PropTypes.any,
  onClick: PropTypes.func
}

const MenuButtonContainerStyled = styled(Grid)`
  ${({ theme }) => theme.box(25, 25, true)}
  background-color: ${({ theme }) => theme.palette.primary.main};
  fill: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.main};
  ${({ theme }) => theme.position('absolute', 35 - 15, -15)};
  ${({ theme }) => theme.media.desktopDown`display: none;`}
`
const MobileMenuHeader = styled.div`
  display: flex;
  padding: 24px 20px;
  width: 100%;
  gap: 8px;
  align-items: center;
  ${({ theme }) => theme.media.desktop`display: none;`}
  box-sizing: border-box;
`

const WiggotIconStyled = styled(WiggotIcon)`
  ${({ theme }) => theme.box(36, 36)}
  color: #222;
  ${({ theme }) => theme.media.desktop`display: none;`}
`

const CrossIconStyled = styled(Cross)`
  ${({ theme }) => theme.box(24, 24)}
  fill: #222;
  color: ${({ theme }) => theme.palette.base.white};
  ${({ theme }) => theme.media.desktop`display: none;`}
`

const Separator = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.remCalc('8')};
  margin: ${({ theme }) => theme.remCalc('0 8 8')};
  background-color: rgb(235 237 240/1);
  ${({ theme }) => theme.media.desktop`display: none;`}
`
const CustomProfileNameText = styled.span`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: rgb(34 34 34/1);
  font-family: 'Red Hat Display', sans-serif !important;
`
const CustomActionText = styled.span`
  color: rgb(114 110 125/1);
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: 'Red Hat Display', sans-serif !important;
`

export default MenuButton
