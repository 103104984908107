import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Autocomplete, withForm, Grid, Select, Spinner } from 'rc'

import { GetAddressCities, GetAddressSettlements } from 'reduxActions/general'

import { FormContext } from 'rc/FormContainer'
import { LocationHighlightedItem } from 'components/CommonStyled'
import { MEXICAN_STATES } from 'utils/location'

const LocationsSelectFields = ({
  fc,
  stateFieldConfig,
  cityFieldConfig,
  neighborhoodFieldConfig,
  token,
  GetAddressCities,
  GetAddressSettlements,
  idBase,
  latLng,
  isLocation,
  optionsHeight,
  itemStateLabel,
  itemStateCss,
  itemCityLabel,
  itemCityCss,
  getGenericNeighborhood,
  ...props
}) => {
  const [isLoading] = useState(false)
  /**
   * Get info of state value
   */
  const {
    value: statePropValue,
    name: stateFieldName,
    onChange: onStateFieldChange,
    error: errorStateField
  } = stateFieldConfig
  const [stateLocalValue, setStateLocalValue] = useState('')
  const isControlled =
    statePropValue !== undefined || fc.values[`${stateFieldName}`] !== undefined
  const stateFieldValue = isControlled
    ? statePropValue || fc.values[`${stateFieldName}`]
    : stateLocalValue
  const errorStateHelperText =
    fc && fc.errors ? fc.errors[`${stateFieldName}`] : errorStateField

  /**
   * Get info city value
   */
  const {
    value: cityPropValue,
    name: cityFieldName,
    onChange: onCityFieldChange,
    error: errorCityField,
    isHiddenCity = false
  } = cityFieldConfig
  const [cityLocalValue, setCityLocalValue] = useState('')
  const cityFieldValue = isControlled
    ? cityPropValue || fc.values[`${cityFieldName}`]
    : cityLocalValue
  const errorCityHelperText =
    fc && fc.errors ? fc.errors[`${cityFieldName}`] : errorCityField
  /**
   * Get info neighborhood value
   */
  const {
    value: neighborhoodPropValue,
    name: neighborhoodFieldName,
    onChange: onNeighborhoodFieldChange,
    error: errorNeighborhoodField
  } = neighborhoodFieldConfig

  const [neighborhoodLocalValue, setNeighborhoodLocalValue] = useState('')
  const neighborhoodFieldValue = isControlled
    ? neighborhoodPropValue || fc.values[`${neighborhoodFieldName}`]
    : neighborhoodLocalValue

  const errorNeighborhoodHelperText =
    fc && fc.errors
      ? fc.errors[`${neighborhoodFieldName}`]
      : errorNeighborhoodField

  const [cityConfig, setCityConfig] = useState({
    disabled: stateFieldValue ? false : true
  })
  const [neighborhoodConfig, setNeighborhoodConfig] = useState({
    disabled: cityFieldValue ? false : true
  })

  const handleSelectedState = ({
    target: { value: selectedValue },
    ...extra
  }) => {
    if (!selectedValue) {
      setCityConfig({
        disabled: true
      })
      setNeighborhoodConfig({
        disabled: true
      })
      if (isControlled) {
        if (fc && stateFieldName) {
          fc.customChange({
            [`${stateFieldName}`]: '',
            [`${cityFieldName}`]: '',
            [`${neighborhoodFieldName}`]: ''
          })
        } else if (onStateFieldChange) {
          onStateFieldChange('')
        }
      }

      setCityLocalValue('')
      setNeighborhoodLocalValue('')
      return
    }
    const stateNameSelected = selectedValue
    if (isControlled) {
      if (fc && stateFieldName) {
        const stateSelected = MEXICAN_STATES?.filter(
          state => state.name === selectedValue
        )
        const state = stateSelected && stateSelected[0]
        const latLng = state
          ? {
              latitude: state.latitude,
              longitude: state.longitue
            }
          : {
              latitude: 19.3907336,
              longitude: -99.14361265
            }
        fc.customChange({
          [`${stateFieldName}`]: stateNameSelected,
          [`${cityFieldName}`]: '',
          [`${neighborhoodFieldName}`]: '',
          ...latLng
        })
        fc.setCustomErrors({
          ...fc.errors,
          [`${stateFieldName}`]: ''
        })
      }
      if (onStateFieldChange) {
        onStateFieldChange(stateNameSelected)
      }
    } else {
      setStateLocalValue(stateNameSelected)
      setCityLocalValue('')
      setNeighborhoodLocalValue('')
    }

    setCityConfig({
      disabled: false
    })

    setNeighborhoodConfig({
      disabled: true
    })
  }

  const handleSelectedCity = (autocomplete_name, selectedValue) => {
    const cityNameSelected = selectedValue && selectedValue.name
    if (isControlled) {
      if (fc && cityFieldName) {
        if (latLng) {
          selectedValue
            ? fc.customChange({
                [`${cityFieldName}`]: cityNameSelected,
                latitude: selectedValue.latitude,
                longitude: selectedValue.longitue,
                [`${neighborhoodFieldName}`]: ''
              })
            : fc.customChange({
                [`${cityFieldName}`]: cityNameSelected,
                [`${neighborhoodFieldName}`]: ''
              })
          setNeighborhoodLocalValue('')
        } else {
          fc.customChange({
            [`${cityFieldName}`]: cityNameSelected
          })
        }

        fc.setCustomErrors({
          ...fc.errors,
          [`${cityFieldName}`]: ''
        })
      }
      if (onCityFieldChange) {
        onCityFieldChange(cityNameSelected)
      }
    } else {
      setCityLocalValue(cityNameSelected)
      setNeighborhoodLocalValue('')
    }
    setNeighborhoodConfig({
      disabled: false
    })
  }

  const handleSelectedNeighborhood = (autocomplete_name, selectedValue) => {
    const neighborhoodNameSelected = selectedValue && selectedValue.name
    if (isControlled) {
      if (fc && neighborhoodFieldName) {
        if (latLng) {
          fc.customChange({
            [`${neighborhoodFieldName}`]: neighborhoodNameSelected,
            latitude: selectedValue.latitude,
            longitude: selectedValue.longitue
          })
        } else {
          fc.customChange({
            [`${neighborhoodFieldName}`]: neighborhoodNameSelected
          })
        }

        fc.setCustomErrors({
          ...fc.errors,
          [`${neighborhoodFieldName}`]: ''
        })
      }
      if (onNeighborhoodFieldChange) {
        onNeighborhoodFieldChange(neighborhoodNameSelected)
      }
    } else {
      setNeighborhoodLocalValue(neighborhoodNameSelected)
    }
  }

  const onKeyPressedCityInput = e => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      if (isControlled) {
        if (fc && cityFieldName) {
          fc.customChange({
            [`${cityFieldName}`]: ''
          })
        }
        if (onCityFieldChange) {
          onCityFieldChange('')
        }
      } else {
        setCityLocalValue('')
      }
    }
  }

  const onKeyPressedNeighborhoodInput = e => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      if (isControlled) {
        if (fc && neighborhoodFieldName) {
          fc.customChange({
            [`${neighborhoodFieldName}`]: ''
          })
        }
        if (onNeighborhoodFieldChange) {
          onNeighborhoodFieldChange('')
        }
      } else {
        setNeighborhoodLocalValue('')
      }
    }
  }

  const fetchPromise = (fetchFunction, props) =>
    new Promise((resolve, reject) =>
      fetchFunction(...props)
        .then(res => {
          let {
            payload: { data }
          } = res
          resolve(data)
        })
        .catch(() => {
          resolve([])
        })
    )

  const getCities = inputValue =>
    fetchPromise(GetAddressCities, [token, stateFieldValue, inputValue])

  const getNeighborhoods = inputValue =>
    fetchPromise(GetAddressSettlements, [
      token,
      stateFieldValue,
      cityFieldValue,
      inputValue,
      getGenericNeighborhood
    ])

  return !isLoading ? (
    <FormContext.Provider value={null}>
      <Grid container item flat {...props}>
        <Grid item {...stateFieldConfig.gridItem}>
          {itemStateLabel || null}
          <Select
            id={`${idBase}-select-state`}
            selectId={`${idBase}-select-state`}
            iconId={`${idBase}-select-state`}
            placeholder="Estado *"
            optionLabelKey="name"
            optionValueKey="name"
            options={MEXICAN_STATES}
            customHeight={optionsHeight}
            onChange={handleSelectedState}
            value={stateFieldValue}
            error={errorStateHelperText}
            helperText={errorStateHelperText}
            css={itemStateCss || `margin-top: 16px;`}
            disabled={stateFieldConfig.disabled}
          />
        </Grid>
        {!isHiddenCity && (
          <Grid item {...cityFieldConfig.gridItem}>
            {itemCityLabel || null}
            <Autocomplete
              shouldClearOnEmpty
              id={`${idBase}-select-city`}
              fetchValueResolver={results => {
                return results
              }}
              placeholder="Ciudad *"
              fetchFunction={getCities}
              InputProps={{
                onKeyDown: onKeyPressedCityInput
              }}
              labelItemKey="name"
              listItemComponent={LocationHighlightedItem}
              onChange={handleSelectedCity}
              value={cityFieldValue}
              disabled={cityConfig.disabled ? true : cityFieldConfig.disabled}
              label="Ciudad"
              error={errorCityHelperText}
              helperText={errorCityHelperText}
              customHeight={200}
            />
          </Grid>
        )}
        {!neighborhoodFieldConfig.isHidden && (
          <Grid item {...neighborhoodFieldConfig.gridItem}>
            <Autocomplete
              shouldClearOnEmpty
              shouldFetchOnFocus
              id={`${idBase}-autocomplete-neighborhood`}
              fetchValueResolver={results => {
                return results
              }}
              placeholder="Colonia"
              fetchFunction={getNeighborhoods}
              InputProps={{
                onKeyDown: onKeyPressedNeighborhoodInput
              }}
              labelItemKey="name"
              listItemComponent={LocationHighlightedItem}
              onChange={handleSelectedNeighborhood}
              value={neighborhoodFieldValue}
              disabled={
                neighborhoodConfig.disabled
                  ? true
                  : neighborhoodFieldConfig.disabled
              }
              label="Colonia"
              error={errorNeighborhoodHelperText}
              helperText={errorNeighborhoodHelperText}
              customHeight={200}
            />
          </Grid>
        )}
      </Grid>
    </FormContext.Provider>
  ) : (
    <Grid container center>
      <Spinner />
    </Grid>
  )
}

LocationsSelectFields.defaultProps = {
  stateFieldConfig: {
    gridItem: {
      md: 12
    }
  },
  cityFieldConfig: {
    gridItem: {
      md: 12
    }
  },
  neighborhoodFieldConfig: {
    gridItem: {
      md: 12
    }
  },
  optionsHeight: '200'
}

LocationsSelectFields.propTypes = {
  GetAddressCities: PropTypes.func.isRequired,
  GetAddressSettlements: PropTypes.func.isRequired,
  getGenericNeighborhood: PropTypes.bool,
  token: PropTypes.string.isRequired,
  fc: PropTypes.object,
  stateFieldConfig: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    error: PropTypes.string,
    isHidden: PropTypes.bool,
    gridItem: PropTypes.object,
    disabled: PropTypes.bool
  }),
  cityFieldConfig: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    error: PropTypes.string,
    isHidden: PropTypes.bool,
    gridItem: PropTypes.object,
    disabled: PropTypes.bool
  }),
  neighborhoodFieldConfig: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    error: PropTypes.string,
    isHidden: PropTypes.bool,
    gridItem: PropTypes.object,
    disabled: PropTypes.bool
  }),
  idBase: PropTypes.string,
  latLng: PropTypes.bool,
  isLocation: PropTypes.bool,
  optionsHeight: PropTypes.string,
  itemStateLabel: PropTypes.any,
  itemStateCss: PropTypes.string,
  itemCityLabel: PropTypes.any,
  itemCityCss: PropTypes.string
}

const mapStateToProps = ({ authReducer: { token } }) => ({ token })

const mapDispatchToProps = {
  GetAddressCities,
  GetAddressSettlements
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withForm(LocationsSelectFields))
