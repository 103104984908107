/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState, useContext } from 'react'
import { bool } from 'prop-types'
import styled, { ThemeContext } from 'styled-components'
import { useHistory } from 'react-router-dom'

import { RouterNavLink } from 'rc'
import { Cross } from 'rc/Icons'
import { useMedia } from 'rc/Hooks'
import { LogOut } from 'reduxActions/auth'
import { NotificationsModule } from 'modules/app/notifications'
import { useSelector, useDispatch } from 'react-redux'
import { AccountOptions } from 'modules/app'
// import { PlanCounter } from 'modules/app/components'
import {
  ManageAccountsIcon,
  SwitchAccountIcon,
  LogoutIcon,
  MenuIcon
} from 'components/monopolioUI'
import { Button } from 'components'
import { EventRx, EVENTS } from 'utils/event'

function NavBar(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const rightElement = useRef(null)
  const [rightWidth, setRightWidth] = useState(0)
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const { roles, isSelectDevelop, fullname, token } = useSelector(
    ({
      authReducer: { roles, isSelectDevelop, token },
      profileReducer: { fullname }
    }) => ({
      token,
      roles,
      isSelectDevelop,
      fullname
    })
  )
  // eslint-disable-next-line no-unused-vars
  const userTheme = useSelector(({ authReducer: { theme } }) => ({ theme }))
  const {
    devices: { desktop, tablet, tabletDown }
  } = useContext(ThemeContext)
  const isDesktop = useMedia(desktop)
  const isTabletDown = useMedia(tabletDown)

  const hasRoles = Object.keys(roles).length > 0
  const ROLDEVELOPER =
    roles.DEVELOPER || roles.ROLE_ADMIN_DEVELOPER
      ? isSelectDevelop
        ? true
        : false
      : false

  useEffect(() => {
    if (rightElement && rightElement.current)
      setRightWidth(rightElement.current.getBoundingClientRect().width)
  }, [rightElement])

  const baseClass = 'navbar'

  const accountMenuOptions = [
    {
      isPrincipal: true,
      name:
        (roles.ROLE_ADMIN_DEVELOPER && isSelectDevelop) || roles.DEVELOPER_USER
          ? 'Cuenta'
          : fullname,
      to: () => {
        if (hasRoles && !roles.ASSOCIATION && !isSelectDevelop) {
          return window.location.replace(
            `${process.env.REACT_APP_NEW_APLICATION_URL}/account/profile`
          )
        }

        if (roles.DEVELOPER_USER || ROLDEVELOPER) {
          return history.push('/developmentAccount')
        }

        return history.push('/association/agencies')
      },
      show:
        (hasRoles && !roles.ASSOCIATION && !isSelectDevelop) ||
        (roles.DEVELOPER_USER && !isTabletDown) ||
        (roles.ROLE_ADMIN_DEVELOPER && !isTabletDown)
    },
    {
      name: 'Cambiar rol',
      icon: SwitchAccountIcon,
      to: () => history.push('/loginrol'),
      show:
        !roles.DEVELOPER_USER && (roles.DEVELOPER || roles.ROLE_ADMIN_DEVELOPER)
    },
    {
      name: 'Salir',
      icon: LogoutIcon,
      show: hasRoles,
      onClick: () => dispatch(LogOut(token))
    }
  ]

  const menuClasses = 'width: 24px; height: 24px; margin-left: 8px;'

  return (
    <Container isUnverified={props.isUnverified}>
      <div
        width={rightWidth}
        css={`
          width: ${({ theme, width }) => theme.remCalc(`${width}`)};
          display: flex;
          align-items: center;
          ${({ theme }) => theme.media.tabletDown`display: none;`}
        `}
      >
        <div
          onClick={() => {
            EventRx.dispatch(EVENTS.SIDE_MENU_OPENED, !isMenuOpened)
            setIsMenuOpened(!isMenuOpened)
          }}
          css="display: flex; align-items: center; padding: 5px 10px; margin: 0 0 0 28px; border-radius: 26px; cursor: pointer; transition: .4s; &:hover { background-color: #F4F7FA; }"
        >
          <span css="text-decoration: underline; font-size: 14px; font-weight: 600; font-family: 'Red Hat Display', sans-serif;">
            Menú
          </span>
          {isMenuOpened ? (
            <Cross css={menuClasses} />
          ) : (
            <MenuIcon css={menuClasses} />
          )}
        </div>
      </div>
      {/* {hasRoles && !roles.DEVELOPER_USER && <PlanCounter />} */}
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <LinkContainer ref={rightElement}>
        {hasRoles ? (
          <>
            {!isSelectDevelop && (
              <>
                <NotificationsModule />
              </>
            )}
            <AccountOptions menuOptions={accountMenuOptions} />
          </>
        ) : (
          <>
            <RouterNavLink to="/login" id={`${baseClass}-link-login`}>
              <Button variant="outlined">Iniciar sesión</Button>
            </RouterNavLink>
            <RouterNavLink to="/register" id={`${baseClass}-link-register`}>
              <Button size="medium">Registrarme</Button>
            </RouterNavLink>
          </>
        )}
      </LinkContainer>
      <MenuIcon
        css={`
          width: 24px;
          height: 24px;
          display: none;
          ${({ theme }) => theme.media.tabletDown`
          display: block;
        `}
        `}
        onClick={() => {
          const menuButton = document.querySelector(
            '[data-toggle="menu-button"]'
          )
          if (menuButton) menuButton.click()
        }}
      />
    </Container>
  )
}

NavBar.propTypes = {
  isUnverified: bool
}

const Container = styled.div`
  z-index: 4;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ theme }) => theme.remCalc('69')};
  background-color: ${({ theme }) => theme.palette.base.white};
  border-bottom: 1px solid #ebedf0;
  ${({ theme }) => theme.position('fixed', null, 0)}
  top: 0;

  ${({ theme }) => theme.media.desktop`
    height: 69px;
    top: 0;
  `}
  ${({ theme }) => theme.media.tabletDown`padding: 0 24px 0 20px;`}
`

const LogoContainer = styled.div`
  padding: ${({ theme }) => theme.remCalc('10 5')};
  margin-left: 10px;
  ${({ theme }) => theme.media.tabletDown`
      margin-left: 0;
  `}
  > svg {
    ${({ theme }) => theme.media.tabletDown`
      max-width: ${({ theme }) => theme.remCalc('200')};
    `}
    width: 119px;
  }
`

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  > * {
    ${({ theme }) => theme.media.tabletDown`display: none;`}
  }
`

export const Logo = styled.img.attrs(({ theme, ...props }) => ({
  src: theme.logo,
  ...props
}))`
  height: ${({ theme, login }) =>
    theme.isExt
      ? login
        ? theme.remCalc('120')
        : theme.remCalc('90')
      : theme.remCalc('27')};
  width: 119px;
  margin-top: ${({ theme }) => (theme.isExt ? theme.remCalc('3') : 0)};
`

export default NavBar
