import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import {
  Enter,
  AddUser,
  Home,
  Building2,
  Article,
  Web,
  UserGroup,
  Exit,
  Checklist,
  Broadcast,
  Blog
} from 'rc/Icons'
import { useMedia } from 'rc/Hooks'
import { ShowModal } from 'reduxActions/services'
import { LogOut } from 'reduxActions/auth'
import { AgencyMissingMinimumFields } from 'reduxActions/agency'

import { Appraisals, LeadManagement } from 'components/Icons'
import { CompleteAgencyInfoMC } from 'components'
import CustomizedMenuHeader from './_CustomizedMenuHeader'
import { NavigationSideBar } from '../components'
import { EventRx, EVENTS } from 'utils/event'
import { useBuyTokens } from 'modules/development/modalContent/hooks'
import {
  SearchIcon,
  // BusinessIcon,
  HouseIcon,
  PermContactCalendarIcon,
  LinkIcon,
  ListIcon,
  LanguageIcon,
  ShoppingCartIcon,
  GroupsIcon,
  SwitchAccountIcon,
  AgencyIcon,
  HomeIcon
} from 'components/monopolioUI'
import { isEnableForFeatureLeadManagement } from 'utils/isEnableFeatureLeadManagement'

function WiggotSideMenu(props) {
  const dispatch = useDispatch()
  const { roles, isSelectDevelop, token, tokens, isOldPro, email } =
    useSelector(
      ({
        authReducer: { roles, isSelectDevelop, token },
        profileReducer: {
          verified: hasEmailVerified,
          notificationsReminder,
          isOldPro,
          email
        },
        addonsReducer: { actives },
        tokensReducer: { tokens }
      }) => ({
        roles,
        isSelectDevelop,
        token,
        hasEmailVerified:
          hasEmailVerified === null || hasEmailVerified === true,
        notificationsReminder,
        actives,
        tokens,
        isOldPro,
        email
      })
    )
  const { buyTokens } = useBuyTokens()

  const hasRoles = Object.keys(roles).length > 0 ? true : false
  const history = useHistory()
  const ROLDEVELOPER =
    roles.DEVELOPER || roles.ROLE_ADMIN_DEVELOPER
      ? isSelectDevelop
        ? true
        : false
      : false

  const {
    devices: { desktop, tabletDown }
  } = useContext(ThemeContext)

  const isDesktop = useMedia(desktop)
  const isTabletDown = useMedia(tabletDown)

  const push = ({ path, state, search }) => {
    history.push({
      pathname: path,
      search: search,
      state: state
    })
  }

  const basic = hasRoles && !ROLDEVELOPER
  const brokerBasic = hasRoles && !roles.ASSOCIATION && !ROLDEVELOPER
  const adminDeveloper =
    roles.ROLE_ADMIN_DEVELOPER && ROLDEVELOPER && !isTabletDown
  const developerAdmin = roles.ROLE_ADMIN_DEVELOPER && !isTabletDown
  const SECTION_VALIDATOR = {
    home: brokerBasic,
    search: brokerBasic,
    developmentSearch: brokerBasic,
    properties: brokerBasic,
    contacts: brokerBasic,
    agencyBrokers:
      !ROLDEVELOPER && ((brokerBasic && !roles.AGENCY_BROKER) || roles.AGENCY),
    dashboard: brokerBasic,
    linkPortals:
      hasRoles &&
      (roles.AGENCY || !roles.AGENCY_BROKER) &&
      !roles.ASSOCIATION &&
      !ROLDEVELOPER,
    website: !ROLDEVELOPER && brokerBasic,
    metricool: isOldPro,
    store: basic,
    addons: hasRoles && !ROLDEVELOPER,
    developments: ROLDEVELOPER,
    developmentsPromotions: adminDeveloper,
    developmentsReports: adminDeveloper,
    developmentLinkPortals: roles.ROLE_ADMIN_DEVELOPER && roles.DEVELOPER_USER,
    developmentsSelectRol:
      !roles.DEVELOPER_USER && (roles.DEVELOPER || roles.ROLE_ADMIN_DEVELOPER),
    register: !hasRoles,
    login: !hasRoles,
    exits: hasRoles,
    listsManager: brokerBasic,
    crm: brokerBasic && !isEnableForFeatureLeadManagement(email),
    leadManagement: brokerBasic && isEnableForFeatureLeadManagement(email),
    createAgency:
      !isDesktop &&
      hasRoles &&
      !roles.ASSOCIATION &&
      !roles.AGENCY &&
      !roles.AGENCY_BROKER &&
      !ROLDEVELOPER &&
      !roles.DEVELOPER_USER,
    developerDevelopmentsPromotion: developerAdmin,
    developerDevelopmentsReports: developerAdmin
  }

  const options = {
    Home: SECTION_VALIDATOR['home'] && {
      home: {
        name: 'Inicio',
        icon: HomeIcon,
        show: true,
        id: 'general-navigationBar-link-home',
        onClick: () =>
          window.location.replace(
            `${process.env.REACT_APP_NEW_APLICATION_URL}/home`
          )
      }
    },
    // Buscar
    Bolsa: (SECTION_VALIDATOR['search'] ||
      SECTION_VALIDATOR['developmentSearch'] ||
      SECTION_VALIDATOR['listsManager']) && {
      search: {
        name: 'Buscador',
        icon: SearchIcon,
        show: SECTION_VALIDATOR['search'],
        id: 'general-navigationBar-link-searchSection',
        onClick: () => {
          return window.location.replace(
            `${process.env.REACT_APP_NEW_APLICATION_URL}/search`
          )
        },
        extraAction: () => EventRx.dispatch(EVENTS.SEARCHER_BACK)
      },
      lists: {
        name: 'Listas',
        icon: ListIcon,
        show: true,
        id: 'general-navigationBar-link-listsManager',
        onClick: () =>
          window.location.replace(
            `${process.env.REACT_APP_NEW_APLICATION_URL}/lists`
          )
      }
    },
    // CRM
    CRM: (SECTION_VALIDATOR['properties'] ||
      SECTION_VALIDATOR['contacts'] ||
      SECTION_VALIDATOR['agencyBrokers']) && {
      properties: {
        name: 'Propiedades',
        icon: HouseIcon,
        show: SECTION_VALIDATOR['properties'],
        id: 'general-navigationBar-link-propertiesSection',
        onClick: () =>
          window.location.replace(
            `${process.env.REACT_APP_NEW_APLICATION_URL}/my-properties`
          )
      },
      leadManagement: {
        name: 'Tablero de seguimiento',
        icon: LeadManagement,
        show: SECTION_VALIDATOR['leadManagement'],
        newFeatures: true,
        onClick: () =>
          window.location.replace(
            `${process.env.REACT_APP_NEW_APLICATION_URL}/lead-management`
          )
      },
      addons: {
        name: 'Estimación de valor',
        icon: Appraisals,
        show: SECTION_VALIDATOR['addons'],
        newFeatures: true,
        onClick: () =>
          window.location.replace(
            `${process.env.REACT_APP_NEW_APLICATION_URL}/appraisals`
          )
      },
      crm: {
        name: 'CRM',
        icon: PermContactCalendarIcon,
        show: SECTION_VALIDATOR['crm'],
        id: 'general-navigationBar-link-crmSection',
        to: '/crm'
      }
    },
    Agencia: SECTION_VALIDATOR['createAgency'] && {
      createAgency: {
        show: SECTION_VALIDATOR['createAgency'],
        to: '/newAgency',
        name: 'Crear cuenta inmobiliaria',
        icon: AgencyIcon
      }
    },
    // Asociaciones
    _: roles.ASSOCIATION && {
      associationAgencies: {
        show: roles.ASSOCIATION,
        to: '/association/agencies',
        name: 'Inmobiliarias',
        icon: Building2
      },
      associationProperties: {
        show: roles.ASSOCIATION,
        to: '/association/properties',
        name: 'Propiedades',
        icon: Home
      },
      associationBrokers: {
        show: roles.ASSOCIATION,
        to: '/association/agencybrokers',
        name: 'Asesores',
        icon: UserGroup
      },
      associationSite: {
        show: roles.ASSOCIATION,
        name: 'Sitio web',
        icon: Web,
        onClick: () => handleAgencySiteVerification()
      },
      associationReports: {
        show: roles.ASSOCIATION,
        to: '/reports',
        name: 'Reportes',
        icon: Article
      }
    },
    // Enlace
    Web: (SECTION_VALIDATOR['linkPortals'] ||
      SECTION_VALIDATOR['website'] ||
      SECTION_VALIDATOR['metricool']) && {
      linkPortals: {
        name: 'Vinculaciones',
        icon: LinkIcon,
        show: SECTION_VALIDATOR['linkPortals'],
        onClick: () =>
          window.location.replace(
            `${process.env.REACT_APP_NEW_APLICATION_URL}/manage-platforms`
          )
      },
      website: {
        name: 'Sitio web',
        icon: LanguageIcon,
        show: SECTION_VALIDATOR['website'],
        id: 'general-navigationBar-link-agencySiteSection',
        onClick: () => handleAgencySiteVerification()
      },
      metricool: {
        name: 'Wiggot Social',
        icon: GroupsIcon,
        show: SECTION_VALIDATOR['metricool'],
        newFeatures: true,
        onClick: () => history.push('/addon/social')
      }
    },
    // Comprar
    Tienda: SECTION_VALIDATOR['store'] && {
      store: {
        name: 'Tienda',
        icon: ShoppingCartIcon,
        show: SECTION_VALIDATOR['store'],
        newFeatures: true,
        onClick: () =>
          window.location.replace(
            `${process.env.REACT_APP_NEW_APLICATION_URL}/shop`
          )
      }
    },
    // Desarrollador Mixto
    Dev: (SECTION_VALIDATOR['developments'] ||
      SECTION_VALIDATOR['developmentsPromotions'] ||
      SECTION_VALIDATOR['developmentsReports'] ||
      SECTION_VALIDATOR['developmentLinkPortals']) && {
      developments: {
        name: 'Inventarios',
        icon: Checklist,
        to: '/developments',
        show: SECTION_VALIDATOR['developments'],
        newFeatures: true
      },
      developmentsPromotion: {
        name: 'Promoción',
        icon: Broadcast,
        show: SECTION_VALIDATOR['developmentsPromotions'],
        newFeatures: true,
        onClick: () => {
          const hasElite =
            tokens && tokens.filter(token => token.type === 'elite')
          if (hasElite && hasElite.length > 0) {
            return history.push('/developmentsPromotions')
          }
          return buyTokens()
        }
      },
      developmentsReports: {
        name: 'Reportes',
        icon: Blog,
        show: SECTION_VALIDATOR['developmentsReports'],
        newFeatures: true,
        onClick: () => {
          if (tokens && tokens.length > 0) {
            return history.push('/developmentDashboard')
          }
          return buyTokens()
        }
      },
      developmentLinkPortals: {
        name: 'Vinculaciones',
        icon: LinkIcon,
        to: '/manageDevelopmentsPlatforms?platformType=not_free_portals',
        show: SECTION_VALIDATOR['developmentLinkPortals'],
        newFeatures: true
      },
      developmentsSelectRol: {
        name: 'Cambiar rol',
        icon: SwitchAccountIcon,
        to: '/loginrol',
        show: SECTION_VALIDATOR['developmentsSelectRol'] && isTabletDown,
        newFeatures: true
      }
    },
    // Authentication only mobile
    Login: isTabletDown &&
      (SECTION_VALIDATOR['register'] ||
        SECTION_VALIDATOR['login'] ||
        SECTION_VALIDATOR['exits']) && {
        register: {
          name: 'Iniciar sesión',
          to: '/login',
          show: SECTION_VALIDATOR['register'] && isTabletDown,
          // eslint-disable-next-line react/display-name
          icon: props => (
            <Enter
              {...props}
              css={`
                transform: rotate(180deg);
              `}
            ></Enter>
          )
        },
        login: {
          name: 'Registrarme',
          to: '/register',
          show: SECTION_VALIDATOR['login'] && isTabletDown,
          icon: AddUser
        },
        exits: {
          name: 'Salir',
          icon: Exit,
          show: SECTION_VALIDATOR['exits'] && isTabletDown,
          onClick: () => dispatch(LogOut(token))
        }
      }
  }

  const developerOptions = {
    Dev: {
      register: {
        name: 'Iniciar sesión',
        to: '/login',
        show: SECTION_VALIDATOR['login'],
        // eslint-disable-next-line react/display-name
        icon: props => (
          <Enter
            {...props}
            css={`
              transform: rotate(180deg);
            `}
          ></Enter>
        )
      },
      login: {
        name: 'Registrarme',
        to: '/register',
        show: SECTION_VALIDATOR['register'],
        icon: AddUser
      },
      developments: {
        name: 'Inventarios',
        icon: Checklist,
        to: '/developments',
        show: roles.DEVELOPER_USER
      },
      developmentsPromotion: {
        name: 'Promoción',
        icon: Broadcast,
        show: SECTION_VALIDATOR['developerDevelopmentsPromotion'],
        onClick: () => {
          const hasElite =
            tokens && tokens.filter(token => token.type === 'elite')
          if (hasElite && hasElite.length > 0) {
            return history.push('/developmentsPromotions')
          }
          return buyTokens()
        }
      },
      developmentsReports: {
        name: 'Reportes',
        icon: Blog,
        show: SECTION_VALIDATOR['developerDevelopmentsReports'],
        onClick: () => {
          if (tokens && tokens.length > 0) {
            return history.push('/developmentDashboard')
          }
          return buyTokens()
        }
      },
      developmentLinkPortals: {
        name: 'Vinculaciones',
        icon: LinkIcon,
        to: '/manageDevelopmentsPlatforms?platformType=not_free_portals',
        show: SECTION_VALIDATOR['developmentLinkPortals'],
        subOptions: {
          paidPortals: {
            name: 'Portales de paga',
            show: true,
            onClick: () =>
              push({
                path: '/manageDevelopmentsPlatforms',
                search: '?platformType=not_free_portals'
              })
          }
        }
      },
      Login: SECTION_VALIDATOR['exits'] &&
        isTabletDown && {
          name: 'Salir',
          icon: Exit,
          show: SECTION_VALIDATOR['exits'] && isTabletDown,
          onClick: () => dispatch(LogOut(token))
        }
    }
  }

  const handleAgencySiteVerification = async () => {
    const siteRedirect = () =>
      window.location.replace(
        `${process.env.REACT_APP_NEW_APLICATION_URL}/minisites/configuracion`
      )
    if (roles.AGENCY || roles.ASSOCIATION) {
      let missingFields = []
      await dispatch(AgencyMissingMinimumFields(token))
        .then(({ payload: { data } }) => {
          missingFields = data
        })
        .catch(err => {
          console.log(err)
        })

      if (missingFields.length === 0) siteRedirect()
      else dispatch(ShowModal(CompleteAgencyInfoMC))
    } else {
      siteRedirect()
    }
  }

  return (
    <NavigationSideBar
      options={roles.DEVELOPER_USER ? developerOptions : options}
      menuHeaderCustomized={
        hasRoles
          ? roles.DEVELOPER_USER || ROLDEVELOPER
            ? null
            : !isDesktop
            ? CustomizedMenuHeader
            : null
          : null
      }
    />
  )
}

WiggotSideMenu.propTypes = {}

export default WiggotSideMenu
